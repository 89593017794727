import { Box, Grid } from '@material-ui/core';
import { constProvider } from 'providers';
import React, { useMemo } from 'react';
import { useQuery } from 'react-admin';
import { ListPickerColumns } from '../../../../common';
import { useStyles } from '../patient-dashboard/patient-dashboard.styles';
import { PatientAddressCard } from './patient-address-card/patient-address-card';
import { FormField, PatientCardSection } from './patient-card-section/patient-card-section';
import { PatientInfoCard } from './patient-info-card/patient-info-card';
import moment from 'moment';

const examinationColumns = [
  { source: 'date' },
  { source: 'description' },
  {
    source: 'userId',
    resource: 'USER',
    render({ title, firstName, lastName }) {
      return `${title} ${firstName} ${lastName}`;
    }
  }
] as ListPickerColumns<any>;

const medicationColumns = [
  { source: 'prescriptionDate' },
  { source: 'name' },
  { source: 'dose' },
  { source: 'intake' },
  {
    source: 'userId',
    resource: 'USER',
    refSource: 'name',
    render({ title, firstName, lastName }) {
      return `${title} ${firstName} ${lastName}`;
    }
  }
] as ListPickerColumns<any>;

export function PatientCard(props: any) {
  const { record } = props;
  const classes = useStyles();
  const measurementData = useMemo(
    () => ({ id: record.id, measurement: {} as any, patient: record, examinations: [] }),
    [record]
  );

  const { data: icd } = useQuery({
    type: 'getList',
    resource: constProvider.RESOURCES.PATIENT_EXAMINATION_ICD.URI,
    payload: {
      pagination: { page: 0, perPage: 100 },
      sort: { field: 'name', order: 'ASC' },
      filter: {}
    }
  });

  const interviewFields = useMemo(
    () =>
      [
        { source: 'examinationType', type: 'hidden', defaultValue: 'INTERVIEW' },
        { source: 'examinationDate', defaultValue: moment().toDate(), type: 'date', cols: 6, align: 'right' },
        { source: 'description', defaultValue: '', type: 'string' },
        { source: 'icd10Ids', defaultValue: [], type: 'string', options: icd, multiple: true }
      ] as FormField[],
    [icd]
  );

  const examinationFields = useMemo(
    () =>
      [
        { source: 'examinationType', type: 'hidden', defaultValue: 'EXAMINATION' },
        { source: 'examinationDate', defaultValue: moment().toDate(), type: 'date', cols: 6, align: 'right' },
        { source: 'description', defaultValue: '', type: 'string' },
        { source: 'icd10Ids', defaultValue: [], type: 'string', options: icd, multiple: true }
      ] as FormField[],
    [icd]
  );

  const physicalFields = useMemo(
    () =>
      [
        { source: 'examinationType', type: 'hidden', defaultValue: 'PHYSICAL' },
        { source: 'examinationDate', defaultValue: moment().toDate(), type: 'date', cols: 6, align: 'right' },
        { source: 'description', defaultValue: '', type: 'string' },
        { source: 'icd10Ids', defaultValue: [], type: 'string', options: icd, multiple: true }
      ] as FormField[],
    [icd]
  );

  const medicationFields = useMemo(
    () =>
      [
        { source: 'prescriptionDate', defaultValue: moment().toDate(), type: 'date', cols: 6, align: 'right' },
        { source: 'name', type: 'string', cols: 4 },
        { source: 'dose', type: 'string', cols: 4 },
        { source: 'intake', type: 'string', cols: 4 }
      ] as FormField[],
    []
  );

  const diagnosisColumns = useMemo(
    () =>
      [
        { source: 'date' },
        { source: 'description' },
        {
          source: 'userId',
          resource: 'USER',
          render({ title, firstName, lastName }) {
            return `${title} ${firstName} ${lastName}`;
          }
        }
      ] as ListPickerColumns<any>,
    []
  );

  const diagnosisFields = useMemo(
    () =>
      [
        { source: 'diagnosisDate', defaultValue: moment().toDate(), type: 'date', cols: 6, align: 'right' },
        { source: 'description', defaultValue: '', type: 'string' }
      ] as FormField[],
    []
  );

  return (
    <Box className={classes.container}>
      <Grid container spacing={3}>
        <Grid container item spacing={3} alignItems="stretch">
          <Grid item xs={12} sm={6} md={6}>
            <PatientInfoCard className={classes.card} data={measurementData} />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <PatientAddressCard data={record} />
          </Grid>
          <Grid item xs={12}>
            <PatientCardSection<any>
              listLabel="INTERVIEW_HISTORY"
              label="INTERVIEW"
              listColumns={examinationColumns}
              formFields={interviewFields}
              resourceName="PATIENT_EXAMINATION"
              patientId={record.id}
              filterDefaultValues={useMemo(() => ({ $type: 'INTERVIEW', subresource: record.id }), [record.id])}
            />
          </Grid>
          <Grid item xs={12}>
            <PatientCardSection<any>
              listLabel="EXAMINATION_HISTORY"
              label="EXAMINATION"
              listColumns={examinationColumns}
              formFields={examinationFields}
              resourceName="PATIENT_EXAMINATION"
              patientId={record.id}
              filterDefaultValues={useMemo(() => ({ $type: 'EXAMINATION', subresource: record.id }), [record.id])}
            />
          </Grid>
          <Grid item xs={12}>
            <PatientCardSection<any>
              listLabel="PHYSICAL_HISTORY"
              label="PHYSICAL"
              listColumns={examinationColumns}
              formFields={physicalFields}
              resourceName="PATIENT_EXAMINATION"
              patientId={record.id}
              filterDefaultValues={useMemo(() => ({ $type: 'PHYSICAL', subresource: record.id }), [record.id])}
            />
          </Grid>
          <Grid item xs={12}>
            <PatientCardSection<any>
              listLabel="MEDICATION_HISTORY"
              label="MEDICATION"
              listColumns={medicationColumns}
              formFields={medicationFields}
              resourceName="PATIENT_MEDICATION"
              filterDefaultValues={useMemo(() => ({ $patientId: record.id }), [record.id])}
            />
          </Grid>
          <Grid item xs={12}>
            <PatientCardSection<any>
              listLabel="DIAGNOSIS_HISTORY"
              label="DIAGNOSIS"
              listColumns={diagnosisColumns}
              formFields={diagnosisFields}
              resourceName="PATIENT_DIAGNOSIS"
              patientId={record.id}
              filterDefaultValues={useMemo(() => ({ subresource: record.id }), [record.id])}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
